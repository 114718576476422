<template>
  <el-dialog
    :visible.sync="isDgShow"
    :before-close="resetForm"
    :width="dgWidth"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="0"
    custom-class="dg_title_css form_dg"
  >
    <!-- type=='add'?'新增讲次':'编辑讲次' -->
    <p class="dg_title" slot="title">{{otherConfig.title}}</p>

    <el-form
      v-if="formDataInited"
      v-loading="loading"
      ref="ruleForm"
      size="small"
      class="demo-ruleForm form_dg"
      :style="{width:formWidth,margin:'0 auto'}"
      :model="formData"
      :rules="rules"
      :label-width="labelWidth"
    >

      <el-form-item
        v-for="(item,i) in formItems"
        :key="i"
        :label="item.label?(item.label+':'):''"
        :prop="item.keyName"
      >
      <!-- item.type=='date-time-range'?'BeginTime': -->
      <!-- formData[item.keyName]  -->
        <template v-if="item.type=='input'">
          <el-input v-model="formData[item.keyName]" :disabled="item.disabled" :placeholder="item.placeholder"></el-input>
        </template>
        <template v-else-if="item.type=='select'">
          <el-select v-model="formData[item.keyName]" :placeholder="item.placeholder" @change="selectorChange" style="width:100%;">
            <el-option
              v-for="(item2,i2) in item.selectors"
              :key="i2"
              :label="item2.label"
              :value="item2.val"

            ></el-option>
          </el-select>
        </template>
        <!-- @change="xx" -->
        <template v-else-if="item.type=='radio'">
          <el-radio-group
            v-model="formData[item.keyName]"
            @change="radioChange('CourseChange',item.keyName,item.action)"
          >
            <!-- lineHeight:item.radios.length>5?1.2:1 -->
            <el-radio
              v-for="(item3,i3) in item.radios"
              :key="i3"
              :label="item3.val"
              :style="{marginTop:(item.radios.length>5&&i3>4)?'5px':'0'}"
            >{{item3.label}}</el-radio>
          </el-radio-group>
        </template>

        <!-- @change="xx" -->
        <template v-else-if="item.type=='checkbox'">
          <el-checkbox-group
            :disabled="type != 'add'"
            v-model="formData[item.keyName]"
          >
            <el-checkbox
               v-for="(item3,i3) in item.radios"
              :key="i3"
              :label="item3.val"
            >{{item3.label}}</el-checkbox>
          </el-checkbox-group>
        </template>

        <template v-else-if="item.type=='upload_editVideo'">
          <!-- 后来沟通  编辑只是编辑 视频名称和播放顺序， 视频项不显示  在formItem中配置隐藏 -->
          <el-upload
            class="upload-demo"
            v-if="!Object.keys(fileObj).length"
            :on-success="beCalledFn"
            :on-change="fileChange"
            :on-remove="handleRemove"
            action="suibian"
            accept=".mp4"
            :before-remove="beforeRemove"
            :limit="1"
            :http-request="selfUpload"
            style="width:100%;"
          >
            <el-button plain size="small"  style="width:100%;">
              <span class="iconfont icon-shangchuan" style="vertical-align: text-bottom;"></span>
              上传文件（文件格式.doc且每个不超过10MB)
            </el-button>
          </el-upload>

          <p class="file_text" v-if="Object.keys(fileObj).length>0">
            <span>
              <span class="iconfont icon-shipingeshi" style="color:#a3c3f6;margin-right:3px;"></span>
              <span class="name_width">{{fileObj.Name||'视频是哦'}}</span>

            </span>
            <span>
              <!-- v-if="Number(item.progressPercent)!=NaN&&Number(item.progressPercent) < 100" -->
              <!-- {{item.progressPercent}}  -->
              <template v-if="fileObj.progressPercent!=undefined">
                <el-progress
                  v-if="fileObj.progressPercent&&Number(fileObj.progressPercent) < 100"
                  type="circle"
                  width="14"
                  :show-text="false"
                  :percentage="fileObj.progressPercent"
                  color="#3870F6"
                  stroke-width="2"
                  style="vertical-align:middle;margin-right:10px;"
                ></el-progress>
                <span v-else class="check_icon_css el-icon-check"></span>
              </template>
              <span class="el-icon-delete del_icon_css pointer" @click="removeFile(fileObj.AliVideoID)" ></span>
            </span>
          </p>

        </template>

        <template v-else-if="item.type=='upload_lecture'">
          <!-- 讲义上传 -->
          <el-upload
            class="upload-demo"
            v-if="!Object.keys(fileObj).length"
            :on-success="beCalledFn"
            :on-change="fileChange2"
            :on-remove="handleRemove"
            action="suibian"
            accept=".doc,.docx,.pdf"
            :limit="1"
            :http-request="selfUpload2"
            style="width:100%;"
          >
            <el-button plain size="small"  style="width:100%;">
              <span class="iconfont icon-shangchuan self_shangchuan theme_color"></span>
              上传文件（文件格式.doc .pdf,且每个不超过5MB)
            </el-button>
          </el-upload>

          <p class="file_text" v-if="Object.keys(fileObj).length>0">
            <span>
              <span class="iconfont icon-word" style="color:#a3c3f6;margin-right:3px;"></span>
              <span class="name_width">{{fileObj.name||'视频是哦'}}</span>

            </span>
            <span>
              <!-- v-if="Number(item.progressPercent)!=NaN&&Number(item.progressPercent) < 100" -->
              <!-- {{item.progressPercent}}  -->
              <template v-if="fileObj.progressPercent!=undefined">
                <el-progress
                  v-if="fileObj.progressPercent&&Number(fileObj.progressPercent) < 100"
                  type="circle"
                  width="14"
                  :show-text="false"
                  :percentage="fileObj.progressPercent"
                  color="#3870F6"
                  stroke-width="2"
                  style="vertical-align:middle;margin-right:10px;"
                ></el-progress>
                <span v-else class="check_icon_css el-icon-check"></span>
              </template>
              <span class="el-icon-delete del_icon_css pointer" @click="removeFile2" ></span>
            </span>
          </p>

        </template>


        <template v-else-if="item.type=='date'">
          <el-date-picker
            v-model="formData[item.keyName]"
            type="date"
            placeholder="选择日期"
            style="width:180px;"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </template>
        <!-- 含有子校验元素 -->
        <div v-else-if="item.type=='nested'" style="display:flex;">
          <el-form-item
            v-for="(child,j) in item.children"
            :key="j"
            :prop="child.keyName"
          >
            <el-date-picker
              v-if="child.type=='date-time'"
              v-model="formData[child.keyName]"
              type="datetime"
              popper-class="form_dg_date_time"
              placeholder="选择日期时间"
              style="width:180px;"
              prefix-icon="iconfont icon-rili"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>

            <el-radio-group v-else v-model="formData[child.keyName]" style="margin-left:5px;">
              <el-radio
                v-for="(item3,i3) in child.radios"
                :key="i3"
                :label="item3.val"
              >{{item3.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <template v-else-if="item.type=='date-time-range'">
          <el-date-picker
            v-model="formData[item.keyName]"
            type="datetimerange"
            range-separator="-"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width:100%;"
            prefix-icon="iconfont icon-rili"
            @change="dateTimeChange"
          >
          </el-date-picker>
        </template>
        <template v-else-if="item.type=='time-range'">
          <!-- <el-time-picker
            is-range
            v-model="formData[item.keyName]"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
            value-format="HH:mm"
            class="set_time_split_width"
          ></el-time-picker> -->
          <el-time-select
            @change="timeRangeChange" 
            v-model="formData[item.keyName][0]" 
            :picker-options="{start: '08:00',step: '00:05',end: '21:55'}" 
            placeholder="开始时间"
            :clearable="false"
            value-format="HH:mm"
            style="width:40%">
          </el-time-select>
          <span style="padding-left: 5%;padding-right: 5%;">至</span>
          <el-time-select
            v-model="formData[item.keyName][1]" 
            :picker-options="{start: '08:05',step: '00:05',end: '23:00'}" 
            placeholder="结束时间" 
            :clearable="false"
            value-format="HH:mm" 
            style="width:40%;">
          </el-time-select>
          <!-- .el-date-editor .el-range-separator -->
        </template>

      </el-form-item>

      <!--
      <el-form-item label="员工姓名" prop="realName">
        <el-input
          @blur="
            formData.realName = formData && formData.realName ? formData.realName.trim() : ''
          "
          maxlength="20"
          v-model="formData.realName"
        >
        </el-input>
      </el-form-item>
      -->
        <!-- <el-form-item
          label="生日"
          prop="birthday"
          :rules="
            rowData.birthday ? [{ required: true, message: '生日不能为空', trigger: 'change' }] : []
          "
        >
          <el-date-picker
            v-model="formData.birthday"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
            style="width:100%;"
          >
          </el-date-picker>
        </el-form-item> -->
      <!--楼层列表  待添加  -->

      <!-- <el-form-item label="手机号码" prop="phone">
        <el-input
          :disabled="type == 'edit' ? true : false"
          maxlength="20"
          v-model="formData.phone"
        ></el-input>
      </el-form-item>

      <el-form-item label="证件号码" prop="idCard">
        <el-input maxlength="18" v-model="formData.idCard"></el-input>
      </el-form-item> -->
      <!-- <p class="form_tip ft14 theme_color tc" v-if="otherConfig.tip">{{otherConfig.tip}}</p> -->


      <!-- 给 直播管理->讲次管理->编辑 用的 -->
      <el-checkbox-group
        v-if="otherConfig&&otherConfig.selfFormItems"
        v-model="formData.notifyPeople"
        style="padding-left:18px;"
      >
        <el-checkbox
          v-for="(item3,i3) in otherConfig.selfFormItems"
          :key="i3"
          :label="item3.val"
          style="width:100%;"
        >{{item3.label}}<span v-if="item3.val=='IsDelayOperate' && formData.notifyPeople.includes('IsDelayOperate')">【{{formData.BeginTime.split(' ')[0]}}->{{formData.date}}】</span></el-checkbox>
      </el-checkbox-group>

    </el-form>

    <p class="ft14 theme_color tc form_tip2" v-if="otherConfig.tip">{{otherConfig.tip}}</p>

    <div class="buttonWrap tc" style="margin-top:36px;margin-bottom:0;">
      <el-button
       v-if="needCancel"
        size="small"
        @click="resetForm()"
      >取消</el-button>

      <el-button
        size="small"
        :disabled="loading"
        :loading="loading"
        type="primary"
        style="margin-left:20px;"
        @click="submitForm('ruleForm')"
      >{{subBtnName}}</el-button>
    </div>


  </el-dialog>
</template>

<script>
let axios = window.axios;
let AliyunUpload = window.AliyunUpload;
import { mapGetters } from 'vuex';
export default {
  props: {
    formItems: Array,
    formRules:Object,
    dgWidth:{
      type:String,
      default:'675px',
    },
    formWidth:{
      type:String,
      default:'511px',
    },
    labelWidth:{
      type:String,
      default:'80px'
    },
    needCancel:{
      type:Boolean,
      default:true,
    },
    subBtnName:{
      type:String,
      default:'创建',
    },
  },

  data() {
    return {
      loading: false,
      otherConfig:{},
      type: 'add',
      isDgShow: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      companys: [],
      formDataInited:false,
      formData: {},
      fileObj:{},
      originVideo:'normal',
      uploadConfigObj:{},
      uploader: null,
      file:{},
      rules: this.formRules,
      certificateTypes: [
        {
          value: '1',
          label: '身份证',
        },
        {
          value: '2',
          label: '护照',
        },
      ],
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      classModelSelectors:[],

    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  watch: {
    formItems(n,o){
      console.log(n,this.formItems,'9-----')
    }, 

    formRules(n,o){
      this.rules = this.formRules;
      // console.log(this.rules,'rules')
    }
  },

  methods: {

    show(type,obj){
      console.log('show dialog:', type, obj);
      this.formData = {};
      // 班型联动用的数据 恢复一下
      if(['editClass','addClass'].includes(type)){
        this.resetClassModelSelectors();
      }

      // formData 初始化处理
      this.formDataInit(type,obj);

      if(type.indexOf('edit')>-1){
        this.subBtnName = '保存'
      }

      this.otherConfig = obj.otherConfig
      this.type = type;
      this.isDgShow = true;
    },

    resetClassModelSelectors(){
      this.formItems.forEach(item=>{
        if(item.keyName == 'ClassModel'&&this.classModelSelectors.length){
          item.selectors = this.classModelSelectors;
          this.classModelSelectors = [];
        }
      })
    },

    radioChange(a,keyName,action){
      // 新建班级 科目改变，班型可选项也联动变化 那么班型对应的值也变化
      if(action&&action=='changeThenFiltClassModel'){
        let formItems = this.$dpcp(this.formItems);
        console.log(formItems)
        formItems.forEach(item=>{
          if(item.keyName=='ClassModel'){
            // 如果没有 就备份一个，备份过了就不能再操作，第一次进来时候才是完整的值
            if(!this.classModelSelectors.length){
              this.classModelSelectors = item.selectors;
            }
            item.selectors = this.classModelSelectors.filter(item2=>{
              return item2.CourseID==this.formData[keyName];
            })
          }
        })
        console.log(formItems)
        this.formItems = formItems;
        this.formData['ClassModel']&&(this.formData['ClassModel'] = null);
      }
      console.log(a,'mn',this.formData[keyName])
    },
    removeFile(AliVideoID){
        const data = {
          VideoID:AliVideoID
        }
        let apiName = 'DelOSSVideo';
        this.$api[apiName](data).then(res=>{
          this.$tipOk('已删除')
          this.originVideo = 'deleted'
          this.fileObj = {};
          this.formData.AliVideoID = null;
        }).catch(err=>{})
    },
    removeFile2(){
      this.fileObj = {};
      this.formData.LectureDoc = null;
      this.verifyLecture();
    },

    fileChange2(file,fileList){

    },

    fileChange(file,fileList){

      // const file = fileObj.file;
      // const {name,size} = file;
      // if (size / 1024 / 1024 > 10) {
      //   this.$message({
      //     customClass:'article_msg',
      //     type:'warning',
      //     message:'文件大小不超过10M',
      //     duration:3000,
      //   });
      //   return;
      // }
      // if (name.includes('.doc') || name.includes('.docx')) {
      //   this.fileObj = file;
      //   this.formData.DocFileName = this.fileObj;
      // } else {
      //   this.$message({
      //     customClass:'article_msg',
      //     type: 'warning',
      //     message: '请上传正确的文件类型（.doc .docx)',
      //     duration:3000,
      //   });
      // }
      let title = file.name.split('.')[0];
      let fileName = file.name;
      const data = {
        VideoTitle:title,
        VideoFileName:fileName
      }
      this.$api.GetUploadTicket(data).then(res=>{
        let {UploadAddress,UploadAuth,RequestId,VideoId} = res.TicketInfo;
        let userId = res.AliUserID;
        // 这里传递的值 接口用的是 base64编码的字符串，解码出来是个obj,但是上传凭证应用接口用的是
        // base64字符串
        // let UploadAddress2 = JSON.parse(window.atob(res.TicketInfo.UploadAddress));
        // let UploadAuth2 =  JSON.parse(window.atob(res.TicketInfo.UploadAuth));

        this.uploadConfigObj = {
          RequestId,
          VideoId,
          UploadAddress,
          UploadAuth,
          userId,
        }
        // console.log(this.uploadConfigObj)

        let userData = '{"Vod":{}}'
        if (this.uploader) {
          this.uploader.stopUpload()
        }else{
          this.uploader = this.createUploader()
        }
        this.file = file.raw;
        this.uploader.addFile(this.file, null, null, null, userData)

      }).catch(err=>{})

    },

    dateTimeChange(){
      // this.formData.BeginTime = this.formData.TimeRange[0];
      // this.formData.EndTime = this.formData.TimeRange[1];

      // console.log(this.formData,'554432')
      // if(this.formData.BeginTime){
      //   this.$refs.ruleForm.clearValidate('BeginTime')
      // }else{
      //   this.$refs.ruleForm.validateField('BeginTime')
      // }

    },

    selectorChange(e){
      // console.log(e,11,22)
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    createUploader (type) {
      let config = this.uploadConfigObj;
      let uploader = new AliyunUpload.Vod({
        timeout: config.timeout || 60000,
        partSize: config.partSize || 1048576,
        parallel: config.parallel || 5,
        retryCount: config.retryCount || 3,
        retryDuration: config.retryDuration || 2,
        region: config.UploadAuth.region,
        userId: config.userId,

        // 添加文件成功
        addFileSuccess: (uploadInfo)=>{
          config.uploadDisabled = false
          config.resumeDisabled = false
          config.statusText = '添加文件成功, 等待上传...'
          console.log("addFileSuccess: " + uploadInfo.file.name,uploadInfo)

          let listItem = {};
            listItem.progressPercent = 0;
            listItem.Name = uploadInfo.file.name;
            listItem.ri = uploadInfo.ri;

          this.fileObj = {
            ...this.fileObj,
            ...listItem
          }
          // 然后调用 startUpload 方法, 开始上传
          if (uploader !== null) {
            uploader.startUpload()
            this.uploadDisabled = true
            this.pauseDisabled = false
          }
        },
        // 开始上传
        onUploadstarted: (uploadInfo)=>{
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)

          if (!uploadInfo.videoId) {
              let title = uploadInfo.file.name.split('.')[0];
              let fileName = uploadInfo.file.name;
              const data = {
                VideoTitle:title,
                VideoFileName:fileName
              }
              this.$api.GetUploadTicket(data).then(res=>{
                let {UploadAddress,UploadAuth,RequestId,VideoId} = res.TicketInfo;
                let userId = res.AliUserID;
                let UploadAddress2 = JSON.parse(window.atob(res.TicketInfo.UploadAddress));

                let UploadAuth2 =  JSON.parse(window.atob(res.TicketInfo.UploadAuth));
                console.log(UploadAddress2,'\n',UploadAuth2)

                this.uploadConfigObj = {
                  RequestId,
                  VideoId,
                  UploadAddress:UploadAddress2,
                  UploadAuth:UploadAuth2,
                  userId,
                }
              //   console.log(this.uploadConfigObj)

                let uploadAuth =  res.TicketInfo.UploadAuth
                let uploadAddress = res.TicketInfo.UploadAddress
                let videoId = res.TicketInfo.VideoId
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
              }).catch(err=>{})
              self.statusText = '文件开始上传...'
              console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
            } else {
              let title = uploadInfo.file.name.split('.')[0];
              let fileName = uploadInfo.file.name;
              // 如果videoId有值，根据videoId刷新上传凭证
              // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
              let refreshUrl = `https://vod.aliyuncs.com/?Action=RefreshUploadVideo&Title=${title}&FileName=${fileName}&VideoId=` + uploadInfo.videoId
              axios.get(refreshUrl).then(({data}) => {
                let uploadAuth = data.UploadAuth
                let uploadAddress = data.UploadAddress
                let videoId = data.VideoId
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
              })
            }
        },
        // 文件上传成功
        onUploadSucceed: (uploadInfo)=>{
          console.log('上传成功',uploadInfo)
          console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
          config.statusText = '文件上传成功!'
          this.formData.AliVideoID = uploadInfo.videoId
        },
        // 文件上传失败
        onUploadFailed: (uploadInfo, code, message)=>{
          console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
          config.statusText = '文件上传失败!'
        },
        // 取消文件上传
        onUploadCanceled: (uploadInfo, code, message)=>{
          console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
          config.statusText = '文件已暂停上传'
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: (uploadInfo, totalSize, progress)=>{
          let j,obj;
          obj = {
            ...this.fileObj,
            progressPercent:Math.ceil(progress * 100),
            AliVideoID:uploadInfo.videoId
          }

          this.fileObj = obj;
          console.log('进度',uploadInfo)
          console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
          let progressPercent = Math.ceil(progress * 100)

          config.authProgress = progressPercent
          config.statusText = '文件上传中...'

        },
        // 上传凭证超时
        onUploadTokenExpired: (uploadInfo)=>{
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
          axios.get(refreshUrl).then(({data}) => {
            let uploadAuth = data.UploadAuth
            uploader.resumeUploadWithAuth(uploadAuth)
            console.log('upload expired and resume upload with uploadauth ' + uploadAuth)
          })
          console.log('文件超时')
          // config.statusText ='文件超时'
        },
        // 全部文件上传结束
        onUploadEnd: (uploadInfo)=>{
          console.log("onUploadEnd: uploaded all the files",'文件上传完毕')
          config.statusText = '文件上传完毕'
        }
      })
      return uploader
    },

    // 上传文件 变化时候 对其表单校验
    verifyLecture(){
      if(this.formData.LectureDoc){
        this.$refs.ruleForm.clearValidate('LectureDoc')
      }else{
        this.$refs.ruleForm.validateField('LectureDoc')
      }
    },

    // 自定义上传  讲义
    selfUpload2(fileObj) {
      // console.log(fileObj.file, '自定义');
      const file = fileObj.file;
      const {name,size} = file;
      if (size / 1024 / 1024 > 5) {
        this.$tipWarn('文件大小不超过5M');
        return;
      }
      if (name.includes('.doc') || name.includes('.docx')||name.includes('.pdf')) {
        this.fileObj = file;
        this.$api.uploadPicFn('USR_RES_CLSDOC',file).then(res=>{

          this.formData.LectureDoc = res;
          this.verifyLecture();

        }).catch(err=>{
          console.log(err,'er.err0999')
        })
      } else {
        this.$tipWarn('请上传正确的文件类型（.doc, .docx, .pdf)');
      }
    },

    //上传图片的被调
    beCalledFn(res, file, fileList) {
      // console.log(res,file,fileList,'44')
      this.ruleForm.fileList = [].concat(fileList);
    },

    //切换 身份证 护照时候  校验规则改变
    switchCardValidate(val) {
      let comProp = {
        required: true,
        trigger: 'change',
      };
      if (val == 1) {
        // 身份证  /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
        // 港澳 /^([A-Z]\d{6,10}(\(\w{1}\))?)$/
        // 台：/^[a-zA-Z][0-9]{9}$/
        let reg1 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        let reg2 = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
        let reg3 = /^[a-zA-Z][0-9]{9}$/;
        let idcard = (rule, val1, cb)=>{
          if (val1) {
            if (reg1.test(val1)||reg2.test(val1)||reg3.test(val1)) {
              cb();
            } else {
              cb(new Error(rule.meesage));
            }
          } else {
            cb(new Error(rule.meesage));
          }
        }
        this.rules.idCard = [
          {
            validator: idcard,  //this.$validatorFn.idNumber,
            message: '请输入正确的身份证号码',
            ...comProp,
          },
        ];
      } else if (val == 2) {
        let passport = (rule, val0, cb)=>{
          let reg = /^[a-zA-Z1-9][a-zA-Z0-9]{0,17}$/;
          if (val0) {
            if (reg.test(val0)) {
              cb();
            } else {
              cb(new Error(rule.meesage));
            }
          } else {
            cb(new Error(rule.meesage));
          }
        }
        this.rules.idCard = [
          {
            validator: passport, //this.$validator.passport,
            message: '请输入正确的护照号码',
            ...comProp,
          },
        ];
      } else {
        this.rules.idCard = [
          {
            message: '请输入正确的证件号码',
            ...comProp,
          },
        ];
      }
    },

    cardTypeChange(val) {
      this.$refs.ruleForm.clearValidate('idCard');
      this.formData.idCard = '';
      this.switchCardValidate(val);
    },

    handleNull(rule, val, cb) {
      if (!val && val !== null) {
        cb(new Error(rule.messge));
      } else {
        cb();
      }
    },

    //确认提交
    submitForm(formName) {
      let subFn = () => {
        let data = JSON.parse(JSON.stringify(this.formData));
        if(['addClass','editClass','addLesson','editLesson'].includes(this.type)){
          data.BeginTime = data.TimeRange[0];
          data.EndTime = data.TimeRange[1];
          delete data.TimeRange
        }else if(this.type=='editVideo'){
          const {ID,Name,Company,PlaySort,ClassID,LessonID,AliVideoID} = this.formData;
          data = {ID,Name,Company,PlaySort,ClassID,LessonID,AliVideoID}
        }else if(this.type=='addLiveClass'){
          data.EndTime = '';
        }else if(this.type=='addFolder'){
          // 目录的父目录的id  如果是根目录 就传'0'
          data.PLectureID = this.formData.PLectureID||'0';

          // 重命名文件或文件夹
        }else if(this.type=='reNameFolder'){
          data.LectureName = this.formData.FolderName;
          delete data.FolderName;
        }else if(this.type=='editLiveLesson'){
          // 目录的父目录的id  如果是根目录 就传'0'
          data.BeginTime = data.date+' '+data.TimeRange[0];
          data.EndTime = data.date+' '+data.TimeRange[1];
          if(data.notifyPeople.includes('IsNotifyTeacher')){
            data.IsNotifyTeacher='0';
          }
          if(data.notifyPeople.includes('IsNotifyResearch')){
            data.IsNotifyResearch='1';
          }
          if(data.notifyPeople.includes('IsDelayOperate')){
            data.IsDelayOperate='1';
          }
          delete data.notifyPeople;

          delete data.date;
          delete data.TimeRange;
        }else if(this.type=='setMakeUp'){
          data.BeginTime = data.LessonDate+' '+data.TimeRange[0]
          data.EndTime = data.LessonDate+' '+data.TimeRange[1]
          if(data.notifyPeople.includes('IsNotifyTeacher')){
            data.IsNotifyTeacher='0';
          }
          if(data.notifyPeople.includes('IsNotifyResearch')){
            data.IsNotifyResearch='1';
          }
          data.Status = '0';
          delete data.notifyPeople;
          delete data.LessonDate;
          delete data.TimeRange;
        }

        // 后端 直播和录播的新增 这里用的同一个接口  加 IsLive 判断;
        if(['addClass','addLiveClass'].includes(this.type)){
          data.IsLive = this.type == 'addLiveClass'?'1':'0';
        }

        console.log('FormDialog commit data:', data)
        this.loading = true;
        const apiName = this.otherConfig.apiName;
        this.$api[apiName](data).then((res) => {
          this.$message({
            message: res.ErrMsg,
            type: 'success',
          });

          this.loading = false;
          this.$emit('submitSuccess');
          if(this.type!='editLiveLesson'){
            this.$refs[formName].resetFields();
          }else{
            this.$refs[formName].clearValidate();
          }
          this.isDgShow = false;

        })
        .catch((err) => {
          this.$message({
            message: err.ErrMsg,
            type: 'warning',
          });
          this.loading = false;
        });
      };


      if(Object.keys(this.rules)){
        console.log(this.formData,'data')
        this.$refs[formName].validate((valid) => {
          if (valid) {
            subFn();
          } else {
            console.log('error submit!!');
            this.$tipWarn('请完善信息后再提交')
            return false;
          }
        });
      }else{
        subFn();
      }

    },

    //取消提交
    resetForm() {
      this.isDgShow = false;
      this.loading = false;
      if(this.type == 'editVideo'){
        this.$emit('reload')
      }

      setTimeout(() => {
        this.$refs.ruleForm.resetFields();
      }, 190);
    },

    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //上传之前的文件格式校验
    beforeUpload(file) {
      let accept = '.jpg,.jpeg';
      return this.$comBeforeUpload(file, accept, [15, 180]);
    },

    formDataInit(type,obj){
      this.formItems.forEach(item=>{
        if(item.keyName){
          if(item.keyName=='TimeRange'){
            /** 设置默认时间范围 */
            let startDate = new Date();
            let endDate = new Date();
            endDate.setDate(endDate.getDate()+1);
            let timeRange = [];
            timeRange.push(startDate.toLocaleDateString().replaceAll('/', '-')+' 00:00:00');
            timeRange.push(endDate.toLocaleDateString().replaceAll('/', '-')+' 00:00:00');

            this.$set(this.formData, item.keyName, timeRange);
          }else{
            if(item.defaultValue){
              this.$set(this.formData,item.keyName,item.defaultValue);
            }else{
              this.$set(this.formData,item.keyName,'');
            }
            
            if(item.type == 'radio'){
              item.radios.forEach(radioItem=>{
                if(radioItem.selected == true){
                  this.$set(this.formData,item.keyName, radioItem.val);
                }
              })
            }
          }
        }else if(item.type=='nested'){
          // 表单里面嵌套有表单
          item.children.forEach(item2=>{
            if(item2.keyName == 'BeginTime'){
              /** 开课时间默认为当天时间 */
              let curDate = new Date();
              this.$set(this.formData, item2.keyName, curDate.toLocaleDateString());
            }else{
              this.$set(this.formData, item2.keyName, '');
            }
            
            if(item2.type == 'radio'){
              item2.radios.forEach(radioItem=>{
                if(radioItem.selected == true){
                  this.$set(this.formData,item2.keyName, radioItem.val);
                }
              })
            }
          })
        }
      })

      this.formDataInited = true;
      this.$nextTick(()=>{
        this.$refs.ruleForm.clearValidate();
      })

       // 如果是编辑操作  表单数据的回显处理
      if(type=='editClass'){
        /** 编辑录播班级 */
        let row = this.$dpcp(obj.otherConfig.row);
        row.TimeRange = [row.BeginTime.split('.')[0],row.EndTime.split('.')[0]];

        const {ClassID, Name, NianJie, Term, Course, ClassModel, Grade, Teacher, TimeRange,IsLive,Company} = row;
        this.formData = {ClassID, Name, NianJie, Term, Course, ClassModel, Grade, Teacher, TimeRange,IsLive,Company};
        // console.log(this.formData.TimeRange,'timerange')
      }else if(type=='editLiveClass'){
        /** 编辑直播班级 */
        let row = this.$dpcp(obj.otherConfig.row);
        // 直播  班级管理的编辑 暂时等待添加两个字段
        const {ClassID, Name, NianJie, Term, Course, ClassModel, Grade, Teacher, BeginTime,IsCodeAccess,IsLive,OnlineForm,Company} = row;
        this.formData = {ClassID, Name, NianJie, Term, Course, ClassModel, Grade, Teacher, BeginTime,IsLive,IsCodeAccess,OnlineForm,Company};
        console.log(this.formData.IsCodeAccess,'timerange')
      }else if(type=='addLesson'){
        let row = obj.otherConfig;
        this.formData.ClassID = row.classId;
        this.formData.VideoCnt = 0;
        this.formData.LessonSort = String(row.LessonSort+1);
        this.formData.TimeRange = [row.BeginTime.split('.')[0],row.EndTime.split('.')[0]];
      }else if(type=='editLesson'){
        let row = this.$dpcp(obj.otherConfig.row);
        row.TimeRange = [row.BeginTime.split('.')[0], row.EndTime.split('.')[0]];
        const {ID,LessonSort,Name,ClassID,VideoCnt,LectureDoc,IsOpen,Creator,BeginTime,EndTime,TimeRange} = row;
        if(LectureDoc){
          this.fileObj = {
            name : LectureDoc
          }
        }
        this.formData = {ID,LessonSort,Name,ClassID,VideoCnt,IsOpen,Creator,TimeRange,BeginTime,EndTime};
      }else if(type=='editLiveLesson'){
        // 直播课的 讲次编辑
        let row = this.$dpcp(obj.otherConfig.row);
        const {ID,LessonSort,Name,ClassID,VideoCnt,LectureDoc,IsOpen,Creator,BeginTime,EndTime} = row;
        let arr1 = BeginTime.split(' '),arr2 = EndTime.split(' ')
        let date = arr1[0];
        let TimeRange = [arr1[1].split('.')[0].substr(0, 5), arr2[1].split('.')[0].substr(0, 5)];
        if(LectureDoc){
          this.fileObj = {
            name : LectureDoc
          }
        }
        let notifyPeople = [row.IsNotifyTeacher,row.IsNotifyResearch];
        if(row.IsNotifyTeacher){
          notifyPeople.push('IsNotifyTeacher')
        }
        if(row.IsNotifyResearch){
          notifyPeople.push('IsNotifyResearch')
        }

        this.formData = {ID,LessonSort,Name,ClassID,VideoCnt,LectureDoc,IsOpen,Creator,date,TimeRange,BeginTime,EndTime,notifyPeople};
        //console.log('formData:', this.formData.TimeRange[0],this.formData.TimeRange[1]);
      }else if(type=='setMakeUp'){
        // 直播  设置补课时间
        let row = this.$dpcp(obj.otherConfig.row);
        // row.TimeRange = [row.BeginTime.split('.')[0],row.EndTime.split('.')[0]];
        const {ID,ClassID,VideoCnt,BeginTime,EndTime} = row;
        let time1 = BeginTime.split(' ')[1].split(':')
        let time2 = EndTime.split(' ')[1].split(':')
        time1.pop();
        time2.pop();
        console.log(time1,time2)
        this.formData = {
          ClassID,
          LessonID:ID,
          // LessonDate:BeginTime.split(' ')[0].split('-').join('/'),
          LessonDate:BeginTime.split(' ')[0],
          TimeRange:[time1.join(':'),time2.join(':')],
          notifyPeople:[]
        };
        console.log(this.formData.TimeRange,this.formData.LessonDate,BeginTime)

      }else if(type=='editStudent'){
        this.formData = this.$dpcp(obj.otherConfig.row);

      }else if(type=='editVideo'){
        const {Name,AliVideoID} = obj.otherConfig.row
        this.formData = this.$dpcp(obj.otherConfig.row);
        this.fileObj = {
          ...obj.otherConfig.row
        }
      }else if(type=='addFolder'){
        this.formData.PLectureID = obj.otherConfig.PLectureID
      }else if(type=='reNameFolder'){
        const {LectureName,LectureID} = obj.otherConfig.row

        // 重命名 和 创建文件夹用的不是一个字段，这里不想更改了，表单用
        // 创建文件夹的字段，提交的时候再改成 LectureName
        this.formData = {
          FolderName:LectureName,
          LectureName,
          LectureID
        }
        this.subBtnName = '保存'
      }else{
        console.log();
      }
      this.formDataInited = true;
    },


    //超出文件个数的回调
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 1 个文件，已选择了 1 个文件`);
    // },
    /** 时间（格式例如：08:00）大小比较， */
    timeCompare(time1, time2){
      let t1 = time1.split(':')[0]*60+parseInt(time1.split(':')[1]);
      let t2 = time2.split(':')[0]*60+parseInt(time2.split(':')[1]);
      if(t1>t2){
        return 1;
      }else if(t1==t2){
        return 0;
      }else{
        return -1;
      }
    },    

    timeRangeChange(){
      console.log('timeRangeChange:', this.formData.TimeRange);
      if(this.timeCompare(this.formData.TimeRange[1], this.formData.TimeRange[0]) <= 0){
        /** 讲次的开始时间已修改，检查结束时间，如果结束时间无效则默认设置结束时间为开始时间后两小时 */
        let timePickerEnd = (parseInt(this.formData.TimeRange[0].split(':')[0])+2)+':'+this.formData.TimeRange[0].split(':')[1];
        console.log('timePickerEnd:', timePickerEnd);
        if(this.timeCompare(timePickerEnd, '23:00') > 0){
          timePickerEnd = '23:00';
        }
        this.formData.TimeRange[1] = timePickerEnd;
        this.$forceUpdate();
        
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form_tip{
  width: 511px;
  margin: 24px auto 32px;

}

.form_tip2{
  width:100%;
  height: 36px;
  line-height: 36px;
  background: #F7F8FC;
  margin: 24px auto 32px;
}

.file_text{
  width: 100%;
  height:36px;
  border: 1px solid #DCDFE6;
  border-radius:4px;
  padding:0 10px;
  transition: all .5s cubic-bezier(.55,0,.1,1);
  transition: color .3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name_width{
    width: 320px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .check_icon_css{
    display:inline-block;
    font-size: 11px;;
    padding:1px;
    margin-right:10px;
    color:#ffffff;
    background:green;
    border-radius:50%;
  }
}
.self_shangchuan{
  vertical-align: text-bottom;
}


</style>
<style lang="less">
.form_dg{
  .el-dialog__body{
    padding:30px 0;
  }
  .el-input--small .el-input__icon{
    // line-height: 35px;
    font-size: 18px;
  }
  .el-date-editor .el-range__icon{
    line-height: 29px;
    font-size: 18px;
  }
  .el-form-item--small .el-form-item__label{
    line-height: 35px;
  }

  // 动态控制 大于 1 行 开始设置
  // .el-radio{
  //   line-height: 1.2;
  // }
}
.set_time_split_width.el-date-editor .el-range-separator{
  width:30px;
  line-height:28px;
}
</style>

