import FooterCopyright from './FooterCopyright.vue';
import BlockTitle from './BlockTitle.vue';
import ListSearchbar from './ListSearchbar.vue';
import ListTable from './ListTable.vue';
import BatchUpload from './BatchUpload.vue';
import FormDg from './FormDg.vue';
import TipDg from './TipDg.vue';
import DgWrap from './DgWrap.vue';
import VideoUploadDgBody from './VideoUploadDgBody.vue';
import MyElPopconfirm from './MyElPopconfirm.vue';
import FileIcon from './FileIcon.vue';
import CrumbNav from './CrumbNav.vue';

import VDistpicker from 'v-distpicker'




export default {
  install: (Vue) => {
    Vue.component('FooterCopyright', FooterCopyright);
    Vue.component('BlockTitle', BlockTitle);
    Vue.component('ListSearchbar', ListSearchbar);
    Vue.component('ListTable', ListTable);
    Vue.component('BatchUpload', BatchUpload);
    Vue.component('FormDg', FormDg);
    Vue.component('TipDg', TipDg);
    Vue.component('DgWrap', DgWrap);
    Vue.component('VideoUploadDgBody', VideoUploadDgBody);
    Vue.component('VDistpicker', VDistpicker);
    Vue.component('MyElPopconfirm', MyElPopconfirm);
    Vue.component('FileIcon', FileIcon);
    Vue.component('CrumbNav', CrumbNav);


    // Vue.component('i-title', CustomTitle);
    // Vue.component('AliVerify', AliVerify);
    // Vue.component('AliVerifyMb', AliVerifyMb);
    // Vue.component('MyDatePanel', MyDatePanel);
  },
};
// import CustomTitle from './Title.vue';
// import AliVerify from './AliVerify.vue';
// import AliVerifyMb from './AliVerifyMb.vue';

// import MyDatePanel from './MyDatePanel.vue';
