import request from './request';

// 接口名子趋于后端提供接口字段 没做过多名称更改
export default {
  loginAndGetMainUser: (data,config) => request.post('/Login',data,config),
  AgentBaseData: (data,config) => request.post('/AgentBaseData',data,config),
  TeacherList: (data,config) => request.post('/TeacherList',data,config),
  quit: (data,config) => request.post('/Quit',data,config),
  EditEmployeePwd: (data,config) => request.post('/EditEmployeePwd',data,config),
  GetUploadTicket: (data,config) => request.post('/GetUploadTicket',data,config),
  RefreshUploadTicket: (data,config) => request.post('/RefreshUploadTicket',data,config),

  UploadPic: (data,config) => request.post('/UploadPic',data,config),
  RemoveLectureDoc: (config) => request.post('/RemoveLectureDoc',config),
  DownloadPic: (config) => request.get('/DownloadPic',config),
  UploadCheckMark: (data,config) => request.post('/UploadCheckMark',data,config),  

  ForgetPwdCode: (data,config) => request.post('/ForgetPwdCode',data,config),
  ResetPwdByCode: (data,config) => request.post('/ResetPwdByCode',data,config),


  getListClass: (data,config) => request.post('/ListClass',data,config),
  CreateClass: (data,config) => request.post('/CreateClass',data,config),
  EditClass: (data,config) => request.post('/EditClass',data,config),
  DelClass: (data,config) => request.post('/DelClass',data,config),
  ListClassMember: (data,config) => request.post('/ListClassMember',data,config),
  AddClassMember: (data,config) => request.post('/AddClassMember',data,config),
  DelClassMember: (data,config) => request.post('/DelClassMember',data,config),
  CreateVideo: (data,config) => request.post('/CreateVideo',data,config),
  EditVideo: (data,config) => request.post('/EditVideo',data,config),
  DelOSSVideo: (data,config) => request.post('/DelOSSVideo',data,config),

  getListLiveClass: (data,config) => request.post('/ListLiveClass',data,config),
  ListLecture: (data,config) => request.post('/ListLecture',data,config),
  AddLectureFolder: (data,config) => request.post('/AddLectureFolder',data,config),
  RenameLecture: (data,config) => request.post('/RenameLecture',data,config),
  DelLectureFolder: (data,config) => request.post('/DelLectureFolder',data,config),
  DelLectureDoc: (data,config) => request.post('/DelLectureDoc',data,config),
  CreateMassLesson: (data,config) => request.post('/CreateMassLesson',data,config),
  AddLectureDoc: (data,config) => request.post('/AddLectureDoc',data,config),  
  MoveLecture: (data,config) => request.post('/MoveLecture',data,config),
  ListLessonLecture: (data,config) => request.post('/ListLessonLecture',data,config),
  AddExamPaper2Lesson: (data,config) => request.post('/AddExamPaper2Lesson',data,config),
  ListLessonExamPaper: (data,config) => request.post('/ListLessonExamPaper',data,config),
  DelLessonExamPaper: (data,config) => request.post('/DelLessonExamPaper',data,config),

  OpenVideo: (data,config) => request.post('/OpenVideo',data,config),
  CheckVideo: (data,config) => request.post('/CheckVideo',data,config),
  GetPreviewAuth: (data,config) => request.post('/GetPreviewAuth',data,config),
  DelVideo: (data,config) => request.post('/DelVideo',data,config),

  // 讲次列表
  ListLesson: (data,config) => request.post('/ListLesson',data,config),
  CreateLesson: (data,config) => request.post('/CreateLesson',data,config),
  EditLesson: (data,config) => request.post('/EditLesson',data,config),
  DelLesson: (data,config) => request.post('/DelLesson',data,config),
  ShareLesson: (data,config) => request.post('/ShareLesson',data,config),

  ListVideo: (data,config) => request.post('/ListVideo',data,config),
  ListAllVideo: (data,config) => request.post('/ListAllVideo',data,config),
  ReferVideoAndQuestion: (data,config) => request.post('/ReferVideoAndQuestion',data,config),
  ListReferedQuestion: (data,config) => request.post('/ListReferedQuestion',data,config),
  DelReferedQuestion: (data,config) => request.post('/DelReferedQuestion',data,config),

  getListStudent: (data,config) => request.post('/ListStudent',data,config),
  CreateStudent: (data,config) => request.post('/CreateStudent',data,config),
  ImportStudent: (data,config) => request.post('/ImportStudent',data,config),
  EditStudent: (data,config) => request.post('/EditStudent',data,config),
  ResetStuPassword: (data,config) => request.post('/ResetStuPassword',data,config),


  DelStudent: (data,config) => request.post('/DelStudent',data,config),
  ListStuClass: (data,config) => request.post('/ListStuClass',data,config),
  GetPhoneDetail: (data,config) => request.post('/GetPhoneDetail',data,config),

  getListEmployee: (data,config) => request.post('/ListEmployee',data,config),
  CreateEmployee: (data,config) => request.post('/CreateEmployee',data,config),
  EditEmployee: (data,config) => request.post('/EditEmployee',data,config),
  EditEmployeePurview: (data,config) => request.post('/EditEmployeePurview',data,config),

  ResetUserPassword: (data,config) => request.post('/ResetUserPassword',data,config),
  DelEmployee: (data,config) => request.post('/DelEmployee',data,config),

  getCoInfo: (data,config) => request.post('/ListAgent',data,config),
  CreateAgent: (data,config) => request.post('/CreateAgent',data,config),
  EditAgent: (data,config) => request.post('/EditAgent',data,config),

  // 我的课程相关
  ListLiveClass: (data,config) => request.post('/ListLiveClass',data,config),
  liveListLesson: (data,config) => request.post('/ListLesson',data,config),
  liveAddLectureDoc: (data,config) => request.post('/AddLectureDoc',data,config),
  liveListLessonLecture: (data,config) => request.post('/ListLessonLecture',data,config),
  updateLiveFlag:(data,config) => request.post('/SetLessonConfig',data,config),

  /** 题库接口 */
  ExamQuestionOpenInfo: (data,config) => request.post('/ExamQuestionOpenInfo',data,config),
  ExamQuestionBaseData: (data,config) => request.post('/ExamQuestionBaseData',data,config),
  ExamListExamQuestion: (data,config) => request.post('/ListExamQuestion', data, config),
  ExamGetQuestionDetail: (data,config) => request.post('/ExamQuestionDetail', data, config),
  ExamUpdateQuestion: (data,config) => request.post('/UpdateQuestion', data, config),
  ExamUploadExamQuestion: (data, config) => request.post('/UploadExamQuestion', data, config),
  ExamDelQuestion: (data, config) => request.post('/DelExamQuestion', data, config),
  ExamDownloadPaper: (data, config) => request.post('/DownloadExamPaper', data, config),
  DownloadExamScore: (data, config) => request.post('/DownloadExamScore', data, config), 
  ExamListPaper: (data, config) => request.post('/ListExamPaper', data, config),
  ExamAddPaper: (data, config) => request.post('/AddExamPaper', data, config),
  ExamCreatePaper: (data, config) => request.post('/CreateExamPaper', data, config),  
  ExamDelPaper: (data, config) => request.post('/DelExamPaper', data, config),
  ExamUpdatePaper: (data, config) => request.post('/UpdateExamPaper', data, config),
  ExamListStuPaper:(data, config) => request.post('/ListStuExamPaper', data, config),
  ExamListStuRecord:(data, config) => request.post('/ListStuExamRecord', data, config),
  ExamDelStuRecord:(data, config) => request.post('/DelStuExamRecord', data, config),
  ExamListStuAnswerPaper:(data, config) => request.post('/ListStuExamAnswerPaper', data, config), 
  ExamUpdateStuAnswerPaper: (data, config) => request.post('/UpdateStuExamAnswerPaper', data, config), 
  ExamCheckStuAnswers:(data, config) => request.post('/CheckExamStuAnswers', data, config),
  GetQuestScoreInfo:(data, config) => request.post('/GetQuestScoreInfo', data, config),
  RemarkStuQuestScore:(data, config) => request.post('/RemarkStuQuestScore', data, config),
  ExamUploadStuAttach:(data, config) => request.post('/ExamUploadStuAttach', data, config),
  GetNextUnRemarkedPaper:(data, config) => request.post('/GetNextUnRemarkedPaper', data, config),
  UpdatePaperInRemarking:(data, config) => request.post('/UpdatePaperInRemarking', data, config),

  DownloadRes: (config) => request.get('/DownloadRes',config),
  CreateAdditionLesson: (data,config) => request.post('/CreateAdditionLesson', data, config),
  liveRoomInfo: (data,config) => request.post('/LiveRoomInfo',data,config),
  startLiveLesson: (data,config) => request.post('/StartLesson', data, config),
  stopLesson: (data,config) => request.post('/StopLesson', data, config),
  // 举手开关不用了
  // EnableHandUp: (data,config) => request.post('/EnableHandUp', data, config),
  SetLessonResting: (data,config) => request.post('/SetLessonResting', data, config),

  LiveStuInfo: (data,config) => request.post('/LiveStuInfo', data, config),
  SetStuUpStage: (data,config) => request.post('/SetStuUpStage', data, config),
  GetStuOnStage: (data,config) => request.post('/GetStuOnStage', data, config),

  // EventTs:会话保持时候，此参数是0，其余参数可以不写，调佣频率建议每隔90秒
  UserEventLog: (data,config) => request.post('/UserEventLog', data, config),

  GetSysLog: (data,config) => request.post('/GetSysLog', data, config),
  GetStudyLog: (data,config) => request.post('/GetStudyLog', data, config),
  GetSysCfg: (data,config) => request.post('/GetSysCfg', data, config),
  UpdateSysCfg: (data,config) => request.post('/UpdateSysCfg', data, config), 
  UpdateDevTestLesson: (data,config) => request.post('/UpdateDevTestLesson', data, config),
  UpdateWatchRoleUser: (data,config) => request.post('/UpdateWatchRoleUser', data, config),  

  // getOneTeacherCourses: (data, config) => request.post('/VideoList', data, config),
  VideoPlayAuth: (data,config) => request.post('/VideoPlayAuth', data, config),
  uploadFileFn(ResType,file){
    // Restype = 'USR_RES_AGTSTUXLS'
    return new Promise((resolve,reject)=>{
      // ResType 'USR_RES_AGTSTUXLS',
      let config = {
        onUploadProgress:function(a,b){
          // console.log(a,b,'rrrr')
        }
      };
      const data = new FormData();
      data.append('UploadFile', file)
      data.append('ResType',ResType )
      request.post('/UploadRes',data,config).then(res=>{
        let picName = res.ResName;
        resolve(picName);
      }).catch(err=>{
        reject(null)
      })
    })
  },

  uploadPicFn(ResType,file,PLectureID){
    // Restype = 'USR_RES_AGTSTUXLS'
    return new Promise((resolve,reject)=>{
      // ResType 'USR_RES_AGTSTUXLS',
      let config = {
        onUploadProgress:function(a,b){
          // console.log(a,b,'rrrr')
        }
      };
      const data = new FormData();
      data.append('UploadFile', file)
      data.append('ResType',ResType )
      if(PLectureID){
        data.append('PLectureID',PLectureID )
      }
      request.post('/UploadPic',data,config).then(res=>{
        let picName = res.ResName;
        resolve(picName);
      }).catch(err=>{
        reject(null)
      })
    })
  },

  getPicByName(ResType,picName){
    // Restype = 'USR_RES_AGTSTUXLS'
    return new Promise((resolve,reject)=>{
      let config = {
        responseType: 'arraybuffer',
        params:{
          ResType:ResType,
          PicName:picName,
        }
      }
      let url2 = null;
      request.get('/DownloadPic',config).then(res=>{
        const url = `data: image/jpeg;base64,${btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
        resolve(url);
      }).catch(err=>{
        reject(null)
        // return null;/
      })
    })
  },

  getPicByName2(ResType,ResName){
    // Restype = 'USR_RES_AGTSTUXLS'
    return new Promise((resolve,reject)=>{
      let config = {
        responseType: 'arraybuffer',
        params:{
          ResType:ResType,
          ResName:ResName,
        }
      }
      let url2 = null;
      request.get('/DownloadRES',config).then(res=>{
        const url = `data: image/jpeg;base64,${btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
        resolve(url);
      }).catch(err=>{
        reject(null)
        // return null;/
      })
    })
  }

}
